import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import SkipLinks from '@/shared/components/SkipLinks.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
export default defineComponent({
    name: 'VenueSuccessPage',
    components: {
        SkipLinks
    },
    setup(_, context) {
        const { root } = context;
        const createSpace = () => {
            const name = globalThis.$i18n.locale && globalThis.$i18n.locale !== 'nl'
                ? `${globalThis.$i18n.locale}-${ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN}`
                : ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN;
            // Clean space details before creating a new space
            globalThis.$store.commit('$_vendor/CLEAN_SPACE_DATA');
            globalThis.$router.push({
                name,
                params: { venueId: root.$route.params.venueId }
            });
        };
        onMounted(() => {
            globalThis.$store.commit('$_vendor/CLEAN_VENUE_DATA');
        });
        return {
            BookingRoutes,
            ROUTE_LIST_NAMES,
            createSpace,
            useGetLocalizedPath
        };
    }
});
